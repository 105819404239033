<template>
  <EmailLoader>
    <p>Thank you for using <span class="app-color"><strong>MyPlaceUp!</strong></span></p>

    <p>We've successfully processed your payment of €100.00.</p>

    <p>You can access your subscription information from your settings page.</p>

    <div class="text-center">
      <b-button variant="primary">
        Account Settings
      </b-button>
    </div>
  </EmailLoader>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import EmailLoader from '../setup/loader.vue'

export default {
  components: {
    BButton,
    EmailLoader,
  },
}
</script>
